import cloneDeep from 'lodash/cloneDeep';
import { Enums } from '@flightscope/baseball-stats';
import { Layouts, Images } from '@/components/ui/charts/plotly/PlotlyHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';

import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import plotlyHoverFormats from './plotly/hoverFormats';

export default {
  name: 'ReleaseExtensionChart',

  extends: ChartMiddlewareVue,

  data() {
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.RELEASE_EXTENSION.type,
      plotlyLayoutExtended: cloneDeep(Layouts.scatterChart),
    };
  },

  methods: {
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      const symbol = this.unitSymbolX(this.unitSystem.system);
      this.plotlyLayoutExtended.title.text = this.$vuetify.lang.t('$vuetify.releaseExtensionChart');
      this.plotlyLayoutExtended.xaxis.title = `${this.xAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.xaxis.hoverformat = plotlyHoverFormats.ONE_DIGIT_FLOAT;
      this.plotlyLayoutExtended.yaxis.title = `${this.yAxisTitle}, ${symbol}`;

      const image = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key ?
      Images.releaseExtensionPlayer
      : Images.releaseExtensionPlayerSoftball;

      let convertedReleaseExtensionPlayer = {
        ...image,
        sizex: this.convertToCurrent(image.sizex, this.xAxisConversion).value,
        sizey: this.convertToCurrent(image.sizey, this.yAxisConversion).value,
        sizing: 'fill',
      };

      this.plotlyLayoutExtended.images = [convertedReleaseExtensionPlayer];

      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);
    },
  },
};
